import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
//import { Label  } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';
import ChartDataLabels  from 'chartjs-plugin-datalabels';

import { map } from 'rxjs/operators';
import { Chart, ChartConfiguration, ChartData, ChartEvent, ChartOptions, ChartType, Color } from 'chart.js';

@Component({
  selector: 'app-incentives',
  templateUrl: './incentives.component.html',
  styleUrls: ['./incentives.component.css']
})
export class IncentivesComponent implements OnInit {
  @ViewChild(BaseChartDirective, {static:true}) chart: BaseChartDirective | undefined;

  @Input() customerKey: string;
  @Input() statusColors: any;
  @Input() chartMode: string;
  @Output() updateHeight = new EventEmitter<string>();
  public incentives: any;
  public incentive:any
  public year: number;
  public isLoaded = false;

  public doughnutChartType: ChartType = 'doughnut';
  public doughnutChartOptions: ChartOptions = {
    //rotation: Math.PI * 0.5,
    //legend: true,
    //cutoutPercentage: 15,
    responsive: true,
    plugins: {

      legend: {
        display:false,
        onClick:null
      },
      datalabels: {
        display:true,
        color: ['#000000', '#000000'],
        //align: 'end',
        //:'end',
        anchor:'center',
        //backgroundColor: 'white',
        font: {
          size: 16
        }
      },
      tooltip: {
        //position: 'nearest',
        callbacks: {
          label : function (context) {
              return [context.dataset.label, context.label + ": " + context.formattedValue];
          }
        }        
      },
      
    }
  };

  public barChartType: ChartType = 'bar';
  public barChartOptions: ChartOptions = {
    //rotation: Math.PI * 0.5,
    //legend: true,
    //cutoutPercentage: 15,
    maintainAspectRatio: false,
    responsive: true,
    indexAxis: 'y',
    
    scales: {
      x: {
        stacked: true,
        ticks: {
          color:'black',
          font: {
            size:16,
            weight:500
          }

        }
      },
      y: {
        stacked: true,
        ticks: {
          display: false,
          color: 'black',
          font: { 

            size:16,
            weight:600,
            
          }
        }
      }
    },
    plugins: {

      legend: {
        display:false,
        onClick:null,
        position:'top'
      },
      datalabels: {
        display:true,
        color: ['#000000', '#000000'],
        //align: 'end',
        //:'end',
        anchor:'center',
        //backgroundColor: 'white',
        font: {
          size: 16
        }
      },
      tooltip: {
        //position: 'nearest',
        callbacks: {
          label : function (context) {
              return [context.dataset.label, context.label + ": " + context.formattedValue];
          }
        }        
      },
      
    }
  };

  public barChartLabels: string[] = ['Bronze','Silver','Gold','Rose Gold','Platinum','Sapphire','Diamond','Beyond Diamond'];
  public doughnutChartLabels: string[] = ['Sold', 'Remaining'];

  // for some reason this registers the event for ALL charts
  // TODO - trying to figure out local registration
  public chartPlugins = [ChartDataLabels, {
    id: 'chartPlugins',
    beforeDraw(chart:any, incentive) {
      if (chart.config.type == 'bar')
        return;
      const ctx = chart.ctx;
      var txt1 = 'Total Sold';
      var txt2 = chart.data.datasets[0].data[0];

      //Get options from the center object in options
      const sidePadding = 60;
      const sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)

      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding

      const stringWidth = ctx.measureText(txt1).width;
      const elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      const widthRatio = elementWidth / stringWidth;
      const newFontSize = Math.floor(30 * widthRatio);
      const elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      const fontSizeToUse = 35;
      ctx.font = fontSizeToUse + 'px freight-big-pro';
      ctx.fillStyle = 'black';

      // Draw text in center
      ctx.fillText(txt1, centerX, centerY - 10);
      var fontSizeToUse1 = 50 + 'px freight-big-pro';
      ctx.font = fontSizeToUse1;
      ctx.fillText(txt2, centerX, centerY + 25);

    }
  }];

    
  //public chartData: ChartData<'doughnut'> = [];
  //public legendLabels = [ 'Gold', 'Platinum', 'Diamond' ]

  // figure out this plugin to draw in center of chart
  //public doughnutChartPlugins: PluginServiceGlobalRegistrationAndOptions[] = [{

  //}];

  constructor(private dashboardService: DashboardService) { }

  ngOnInit() {

    const apiPath = 'sales/incentives/';
    this.dashboardService.getApiData(this.customerKey, apiPath).pipe(map((incentives: any[]) => {

      return incentives.map((incentive: any) => {
        if (!this.year) {
          this.year = incentive.premierYear;
        }
        // console.log(incentive);

        const barChartData: ChartData<'bar'> =
        {
          labels: this.barChartLabels,
          
          datasets: [
            {
              label: 'Sold',
              //barThickness:12,
              barPercentage:1,
              categoryPercentage:1,
              //maxBarThickness: 16,
              backgroundColor: [this.statusColors[0].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',
              // datalabels: {
              //   color: ['#000000', '#000000']
              // },
              data: this.getQuantitySold(incentive)
            },
            {
              label: 'Remaining',
              barPercentage:1,
              categoryPercentage:1,
              backgroundColor: [this.statusColors[0].value, this.statusColors[1].value,this.statusColors[2].value,this.statusColors[3].value,this.statusColors[4].value,this.statusColors[5].value,this.statusColors[6].value,this.statusColors[7].value],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',
              // datalabels: {
              //   color: ['#000000', '#000000']
              // },
              data: this.getQuantityRemaining(incentive)
            }
          ]
        };

        // need to redo how the dataset is built
        // RE: NG 14 charts update
        const doughnutChartData: ChartData<'doughnut'> = {
          labels: ['Sold', 'Remaining'],
          datasets: [
            { 
              label: 'Beyond Diamond',
              
              //TODO
              //shouldn't have to do thise for each dataset, shoulc be able to template in options!!!
              backgroundColor: [this.statusColors[7].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',
              
              // datalabels: {
              //   display: true,
              //   color: ['#000000', '#000000']
              // },
              data: [ 
                this.calculateQuantitySold(incentive.beyondDiamond, incentive.quantity), this.calculateQuantityRemaining(incentive.beyondDiamond, incentive.quantity)
              ]
            },
            { 
              label: 'Diamond',
              backgroundColor: [this.statusColors[6].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',

              // datalabels: {
              // color: ['#000000', '#000000'],
              // },
              data: [ this.calculateQuantitySold(incentive.diamond, incentive.quantity), this.calculateQuantityRemaining(incentive.diamond, incentive.quantity) ]
            },
            {
              label: 'Sapphire',
              backgroundColor: [this.statusColors[5].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',

              // datalabels: {
              // color: ['#000000', '#000000'],
              // },
              data: [ this.calculateQuantitySold(incentive.sapphire, incentive.quantity), this.calculateQuantityRemaining(incentive.sapphire, incentive.quantity) ]


            },
            { 
              label: 'Platinum',
              backgroundColor: [this.statusColors[4].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',

              // datalabels: {
              //   color: ['#000000', '#000000']
              // },
              data: [ this.calculateQuantitySold(incentive.platinum, incentive.quantity), this.calculateQuantityRemaining(incentive.platinum, incentive.quantity)] 
            },
            {
              label: 'Rose Gold',
              backgroundColor: [this.statusColors[3].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',

              // datalabels: {
              // color: ['#000000', '#000000'],
              // },
              data: [ this.calculateQuantitySold(incentive.roseGold, incentive.quantity), this.calculateQuantityRemaining(incentive.roseGold, incentive.quantity) ]


            },
            { 
              label: 'Gold',
              backgroundColor: [this.statusColors[2].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',
              // datalabels: {
              //   color: ['#000000', '#000000']
              // },
              data: [ this.calculateQuantitySold(incentive.gold, incentive.quantity), this.calculateQuantityRemaining(incentive.gold, incentive.quantity)] 
            },
            { 
              label: 'Silver',
              backgroundColor: [this.statusColors[1].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',
              // datalabels: {
              //   color: ['#000000', '#000000']
              // },
              data: [ this.calculateQuantitySold(incentive.silver, incentive.quantity), this.calculateQuantityRemaining(incentive.silver, incentive.quantity)] 
            },
            {
              label: 'Bronze',
              backgroundColor: [this.statusColors[0].value, '#ebebe1'],
              borderColor: '#ffffff',
              //hoverBackgroundColor: [,],
              //hoverBorderWidth: 3,
              //hoverBorderColor: 'silver',

              // datalabels: {
              // color: ['#000000', '#000000'],
              // },
              data: [ this.calculateQuantitySold(incentive.bronze, incentive.quantity), this.calculateQuantityRemaining(incentive.bronze, incentive.quantity) ]


            },
          ]
        };


        // this is stupid
        const chartColors = [
          {
            // Beyond Diamond
            backgroundColor: [this.statusColors[7].value, '#ebebe1'],
            hoverBackgroudColor: [this.statusColors[7].value, '#ebebe1'],
            borderColor: '#ffffff',
           // hoverBorderColor:'silver',
            //hoverBorderWidth: 10,
            datalabels: {
              color: ['#000000', '#000000'],

            }

          },
          {
            // Diamond
            backgroundColor: [this.statusColors[6].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000'],

            }
          },
          {
            // Saphire
            backgroundColor: [this.statusColors[5].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },
          {
            // Paltinum
            backgroundColor: [this.statusColors[4].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },
          {
            //Rose Gold
            backgroundColor: [this.statusColors[3].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },
          {
            // Gold
            backgroundColor: [this.statusColors[2].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },
          {
            // Silver
            backgroundColor: [this.statusColors[1].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },
          {
            // Bronze
            backgroundColor: [this.statusColors[0].value, '#ebebe1'],
            borderColor: '#ffffff',
            datalabels: {
              color: ['#000000', '#000000']
            }
          },

        ];

        const legendColors = this.statusColors;
        const legendLabels = this.barChartLabels; // ['SILVER','GOLD', 'PLATINUM', 'DIAMOND', 'BEYOND DIAMOND'];


        return {
          ...incentive,
          legendColors,
          legendLabels,
          chartColors,
          doughnutChartData,
          barChartData
        };
      });
    })).subscribe((response) => {
      this.incentives = response;
      this.isLoaded = true;
      this.updateHeight.emit("Update");
    });

  }

  getQuantitySold(incentive: { quantity: number; bronze:number; silver: number; gold: number; roseGold: number; platinum: number; sapphire: number; diamond: number; beyondDiamond: number; }) {
    return [ 
      incentive.quantity >= incentive.bronze ? incentive.bronze : incentive.quantity,
      incentive.quantity >= incentive.silver ? incentive.silver : incentive.quantity,
      incentive.quantity >= incentive.gold ? incentive.gold : incentive.quantity,
      incentive.quantity >= incentive.roseGold ? incentive.roseGold : incentive.quantity,
      incentive.quantity >= incentive.platinum ? incentive.platinum : incentive.quantity,
      incentive.quantity >= incentive.sapphire ? incentive.sapphire : incentive.quantity,
      incentive.quantity >= incentive.diamond ? incentive.diamond : incentive.quantity,
      incentive.quantity >= incentive.beyondDiamond ? incentive.beyondDiamond : incentive.quantity
      ]
  }

  getQuantityRemaining(incentive: { quantity: number; bronze:number; silver: number; gold: number; roseGold: number; platinum: number; sapphire: number; diamond: number; beyondDiamond: number;}) {
    return [ 
      incentive.quantity >= incentive.bronze ? null : incentive.bronze - incentive.quantity,
      incentive.quantity >= incentive.silver ? null : incentive.silver - incentive.quantity,
      incentive.quantity >= incentive.gold ? null : incentive.gold - incentive.quantity,
      incentive.quantity >= incentive.roseGold ? null : incentive.roseGold - incentive.quantity,
      incentive.quantity >= incentive.platinum ? null : incentive.platinum - incentive.quantity,
      incentive.quantity >= incentive.sapphire ? null : incentive.sapphire - incentive.quantity,
      incentive.quantity >= incentive.diamond ? null : incentive.diamond - incentive.quantity,
      incentive.quantity >= incentive.beyondDiamond ? null : incentive.beyondDiamond - incentive.quantity
      ]
  }

  /// why doesn't this work
  calculateQuantity(level, quantity) {
    return [quantity >= level ? level : quantity, quantity >= level ? null : level - quantity];
    //return quantity >= level ? level : quantity + ',' + quantity >= level ? null : level - quantity;
  }

  calculateQuantityRemaining(level, quantity) {
    return quantity >= level ? null : level - quantity;
  }

  calculateQuantitySold(level, quantity) {
    return quantity >= level ? level : quantity;
  }

  getLevel(incentiveData) {
    
    if (incentiveData.quantity >= incentiveData.beyondDiamond) {
      return 'BEYOND DIAMOND';
    }

    if (incentiveData.quantity >= incentiveData.diamond) {
      return 'DIAMOND';
    }

    if (incentiveData.quantity >= incentiveData.platinum) {
      return 'PLATINUM';
    }

    if (incentiveData.quantity >= incentiveData.gold) {
      return 'GOLD';
    }
    if (incentiveData.quantity >= incentiveData.silver) {
      return 'SILVER';
    }
    else {
      return 'RETAILER';
    }
  }



  // events
  public chartClicked({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: ChartEvent, active: {}[] }): void {
    console.log(event, active);
  }

  lightOrDark(color): string {
    // Variables for red, green, blue values
    let r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {

      // If HEX --> store the red, green, blue values in separate variables
      color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

      r = color[1];
      g = color[2];
      b = color[3];
    }
    else {

      // If RGB --> Convert it to HEX: http://gist.github.com/983661
      color = +("0x" + color.slice(1).replace(
        color.length < 5 && /./g, '$&$&'));

      r = color >> 16;
      g = color >> 8 & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );

    // Using the HSP value, determine whether the color is light or dark
    if (hsp < 155.5) {

      return '#FFFFFF';
    }
    else {

      return '#000000';
    }
  }
  
}
